<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Proje Bilgileri</span>
        </template>
        <validation-observer ref="simpleRules">
          <b-card
            title="Düzenle"
          >
            <validation-observer ref="simpleRules">
              <project-form />
            </validation-observer>
          </b-card>
          <b-row>
            <b-col>
              <b-button
                variant="primary"
                :disabled="dataItem.submitData"
                @click="submitData"
              >
                Kaydet
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="this.$route.params.id_customers" />
      </b-tab>
    </b-tabs>

  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton, BTab, BTabs,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProjectForm from '@/views/Admin/Projects/Components/ProjectForm.vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'

export default {
  name: 'ProjectAdd',
  components: {
    BTabs,
    CustomerHistory,
    CustomerInvoice,
    CustomerInfo,
    BTab,
    ProjectForm,
    BCol,
    BCard,
    BRow,
    BButton,
    ValidationObserver,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projects/dataItem']
    },
    saveData() {
      return this.$store.getters['projects/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.dataItem.submitStatus = false
        if (this.dataItem.id_project_statuses > 1) {
          this.$router.push('/app/projects')
        } else {
          this.$router.push('/app/projects/requests')
        }
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('projects/getDataItem', this.$route.params.id)
        .then(response => {
          this.$store.dispatch('customers/getDataItem', response.id_customers)
          this.checkMarketingPermission(response)
        })
    },
    checkMarketingPermission(project) {
      // kullanıcının pazarlamacı olduğunu sorgula
      if (this.userData.id_user_types === this.$store.state.app.ProjectMarketingUserTypeId) {
        // kullanıcı pazarlamacı ise yetkisi ile projeyi açanın yetkisini eşleştir
        if (this.userData.id !== project.id_users) {
          // projeyi açan kişi pazarlamacı ve eşleşmedi ise yönlendir.
          this.$swal({
            icon: 'error',
            title: 'Erişim Geçersiz.',
            text: 'Kendinize ait olmayan projeleri güncelleyemezsiniz.',
            confirmButtonText: 'Anladım',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$router.push({ name: 'Projects' })
        }
      }
    },

    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.$store.dispatch('projects/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
